import axios from "axios";
import { AUTH_URL } from "../Constant/Url";
import { Http } from "./Http";
import Notifications from "../Presentation/Components/Notifications/Notifications"
import authHeader from "./auth.header"

class AuthService {
  // async loginWs(loginData) {
  //   return await axios.post(BASE_URL + "/auth/signin", loginData);
  // }
  

 loginWs = (credentials) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(AUTH_URL+"/v1/login/", credentials)
        .then((response) => {
          if (response?.data?.status === false) {
            Notifications.error(response?.data?.message);
          } else {
            localStorage.setItem("user", JSON.stringify(response.data));
            Notifications.success("Connexion réussie");
          // dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
            window.location.href = "/dashboard";
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          Notifications.error(error.response.data.errorMessage);
        });
    });
  };

  async logout() {
    const data = localStorage?.getItem('user');
      const token = JSON.parse(data)?.data?.token;

    try {
      const response = await axios.post(
        AUTH_URL+"/v1/logout/",
        {}, // Pass an empty object as the second parameter for POST request
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include Bearer token in the Authorization header
          },
        }
      );
      if (response?.data?.status === true) {
        localStorage.removeItem("user");
        localStorage.clear();
        Notifications.info(response?.data?.message)
        window.location.href = "/";
      }
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle errors or perform actions in case of failure
    }
  }


  async getCurrentUser() {
    try {
      const response = await axios.get(
        AUTH_URL + "/v1/profile/",
        authHeader()
      );
      return response.data;
    } catch (error) {
      throw error.response.data.errorMessage;
    }
  }
}
export default new AuthService();