import Music from "../../../assets/images/music.png"
import Avatar from "../../../assets/images/avatar.png"
import Logout from "../../../assets/images/logout.png"
import Menu from "../../../assets/images/menu.png"
import enIcon from "../../../assets/images/en.png"
import frIcon from "../../../assets/images/fr.png"
import AuthService from "../../../Services/auth.service"
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import i18n from 'i18next';
import StorageService from "../../../Services/storage.service"


const Header = () => {
  const { t } = useTranslation();
  const [enLanguage, setEnLanguage] = useState(false);
  // const userData = StorageService.getItem("userProfile");
  const [userProfile, setUserProfile] = useState("");
 
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });

  const toggleMenu = () => {
    const dropdownButton = document.getElementById('dropdownButton');
    const rect = dropdownButton?.getBoundingClientRect();
    setPosition({ left: rect?.left!, top: rect?.bottom! });
    setIsOpen(!isOpen);
  };

  const handleItemClick = (lChoice : string) => {
    lChoice === "fr" ? setEnLanguage(false) : setEnLanguage(true);
    i18n.changeLanguage(lChoice);
    StorageService.setItem("language", {"language" : lChoice});
    setIsOpen(false)
  };
  useEffect(() => {
    const langData = StorageService.getItem("language");
    langData && i18n.changeLanguage(langData?.language);
    if (langData?.language === "en") {
        setEnLanguage(true)
    }
    AuthService.getCurrentUser().then((response) => {
        localStorage.setItem("userProfile", JSON.stringify(response.data));
        setUserProfile(response?.data?.email)
    })
  }, [])

    const logout = () => {
        AuthService.logout();
    }
    return (
      <div className="d-flex w-full  p-2 header-container">
        <div className="d-flex">
            <img className="ms-2" src={Music} style={{width : 40, height : 40, paddingTop:5}} />
            <p className="text-white fw-bold mt-3 ms-2 text-xl">{t('app_title')} </p>
        </div>
        <div className="d-flex ms-auto">
            <div className="d-flex">
                <img className="mt-1" src={Avatar} style={{width : 20, height : 20}} />
                <p className="text-white fw-bold mt-2 ms-2">{userProfile} </p>
            </div>
            <div className="ms-3 mr-3"> 
                { enLanguage ? (
                     <img className="mt-1" src={enIcon} style={{width : 20, height : 20, cursor: "pointer"}} onClick={toggleMenu}/>
                ) : 
                    <img className="mt-1" src={frIcon} style={{width : 20, height : 20, cursor: "pointer"}} onClick={toggleMenu}/>
                }

                {isOpen && (
                        <div className="language-menu p-3 rounded-xl mt-1 fw-bold text-white" style={{ left: position.left, top: position.top , cursor: "pointer"}}>
                        {/* Menu items */}
                        <div className=" languageChoice" onClick={() => handleItemClick('fr')}>FR</div>
                        <div className=" mt-2 languageChoice" onClick={() => handleItemClick('en')}>EN</div>
                        </div>
                    )}
            </div>
            <div className="ms-auto">
                <img className="ms-3 mt-1 mr-3" src={Logout} style={{width : 20, height : 20,cursor : "pointer" }} onClick={()=>logout()} />
            </div>
            
        </div>
      </div>
    );
  };
  
  export default Header;
  