import Music from "../../../assets/images/music.png";
import Logo from "../../../assets/images/logo_t.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import RightIcon from "../../../assets/images/Right_Icon.svg";
import {
  Col,
  Row,
  Card,
  Badge,
  ListGroup,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import StorageService from "../../../Services/storage.service";
import AuthService from "../../../Services/auth.service";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import frIcon from "../../../assets/images/fr.png"
import enIcon from "../../../assets/images/en.png"



const Login = (props: any) => {
  const { t } = useTranslation();
  const [loginData, setLoginData] = useState({
    matricule: "",
    password: "",
  });
  const [enLanguage, setEnLanguage] = useState(false);
  const userData = StorageService.getItem("user");

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });


  useEffect(() => {
    const langData = StorageService?.getItem("language");
    langData && i18n.changeLanguage(langData?.language);
    if (langData?.language === "en") {
      setEnLanguage(true)
    }
  }, [])
  
  const onChangeLogin = (param: any, text: any) => {
    setLoginData((prevState) => ({
      ...prevState,
      [param]: text.target.value,
    }));
  };

  const login = async (e: any) => {
    e.preventDefault();
    const credentials = {
      matricule: loginData.matricule,
      password: loginData.password,
    };
    AuthService.loginWs(credentials);
  };

  const toggleMenu = () => {
    const dropdownButton = document.getElementById('dropdownButton');
    const rect = dropdownButton?.getBoundingClientRect();
    setPosition({ left: rect?.left!, top: rect?.bottom! });
    setIsOpen(!isOpen);
  };
  const handleItemClick = (lChoice : string) => {
    lChoice === "fr" ? setEnLanguage(false) : setEnLanguage(true);
    i18n.changeLanguage(lChoice);
    StorageService.setItem("language", {"language" : lChoice});
    setIsOpen(false)
  };

  return (
    <div className="container-fluid background-image">
        <Row className="w-full m-auto">
          <Col xs={12} md={12} lg={8} className="d-flex  justify-content-center vh-100">
            <div className="justify-content-center mt-15">
              {/* <div style={{width:30, height:30}}> */}
              <img className="title-image" src={Music} />
              <h1 className="ms-auto text-white title-detail">{t('app_title')} </h1>
              <p className="separator-white"></p>
              <p className="text-white title-description">{t('app_description')}</p>
              {/* </div> */}
                
                
            </div>
          </Col>

          <Col xs={12} md={12} lg={4} className="mt-3 d-fle justify-content-center vh-100">
            <div className="d-flex">
              <p className="text-white text-xs fw-bold mt-2">{t('login_language')}</p>
              <div className="ms-auto">
              { enLanguage ? (
                     <img className="mt-1" src={enIcon} style={{width : 15, height : 15, cursor: "pointer"}} onClick={toggleMenu}/>
                ) : 
                    <img className="mt-1" src={frIcon} style={{width : 15, height : 15, cursor: "pointer"}} onClick={toggleMenu}/>
                }

                {isOpen && (
                        <div className="language-menu p-3 rounded-xl mt-1 fw-bold text-white" style={{ left: position.left, top: position.top , cursor: "pointer"}}>
                        {/* Menu items */}
                        <div className=" languageChoice" onClick={() => handleItemClick('fr')}>FR</div>
                        <div className=" mt-2 languageChoice" onClick={() => handleItemClick('en')}>EN</div>
                        </div>
                    )}
              </div>
            </div>
            <Card className="justify-content-center h-full mb-3 vh-75 login-container">
              <Card.Body className="">
                <h2 className="text-center login-label">{t('login_title')}</h2>
                <p className="separator-black"></p>
              <Form className="form-login">
                    <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
                      <Form.Label className="label-login ">MATRICULE</Form.Label>
                      <Form.Control
                        className="input-login"
                        type="text"
                        placeholder="Matricule"
                        onChange={(text) => onChangeLogin("matricule", text)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className="label-login ">
                        {t('password')}
                      </Form.Label>
                      <Form.Control
                        className="input-login"
                        type="password"
                        placeholder="*********"
                        onChange={(text) => onChangeLogin("password", text)}
                      />
                    </Form.Group>
                    <div className="row justify-content-center">
                      <Button
                        onClick={(event) => login(event)}
                        type="submit"
                        className="btn-login"
                      >
                        {t('login')}
                      </Button>
                    </div>
                  </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </div>
  );
};

export default Login;