import { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "./Presentation/layout/DefaultLayout";
import routes from "./routes";
import React from "react";
import { Provider } from "react-redux";
import store from "./reducers/store";
import Login from "./Presentation/Pages/Auth/Login";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "./custom.css";
import storageService from "./Services/storage.service";
import getUserRole from "./Auth/getUserRole";
import NotFound404 from "./Presentation/Pages/404/404";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next'; // Assuming you've already installed i18next

import enTranslation from '../src/assets/locales/en/translation.json';
import frTranslation from '../src/assets/locales/fr/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
    },
    lng: 'fr', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
 
  const ProtectedRoute = ({
    element: Element,
  }: {
    element: React.ComponentType;
  }) => {
    const token = storageService?.getItem("user")?.data?.token; // Use your authentication context or hook to get the token
    if (!token) {
      // Redirect to the root path if token is null
      return <Navigate to="/" />;
    }
    return <Element />;
  };

  const userRole = getUserRole();
  const canAccessRoute = (role:any, userRole:any) => {
    if (role) {
      return role.includes(userRole);
    }
    return true; // Si la route n'a pas de rôle spécifié, tout le monde peut y accéder
  };
  

  return (
    <Provider store={store}>
      <Helmet>
        <link rel="stylesheet" href="/assets/css/style.css" type="text/css" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"></script>
        <title>STT</title>
      </Helmet>

      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/unauthorized" element={<NotFound404 />}></Route>

        <Route element={<DefaultLayout />}>
          {routes.map(({ path, component: Component, role }) => (
            <Route
              path={path}
              element={
                // canAccessRoute(role, userRole) ? (
                  <Suspense>
                    <ProtectedRoute element={Component} />
                  </Suspense>
                // ) : (
                //   <Navigate to="/unauthorized" />)
              }
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <ToastContainer />
    </Provider>
  );
}

export default App;
