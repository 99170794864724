import { lazy } from "react";

const Dashboard = lazy(() => import("../Presentation/Pages/Dashboard/Dashboard"));
const AudioToText = lazy(() => import("../Presentation/Pages/AudioToText/AudioToText"));
const TextToAudio = lazy(() => import("../Presentation/Pages/TextToAudio/TextToAudio"));

const coreRoutes = [
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
    role : ["admin", "user"]
  },
  {
    path: "/audios",
    title: "Audio to Text",
    component: AudioToText,
    role : ["admin", "user"]
  },
  {
    path: "/texts",
    title: "Text to audio",
    component: TextToAudio,
    role : ["admin", "user"]
  }
];
const routes = [...coreRoutes];
export default routes;
