import NotFound from "../../../../src/assets/images/NotFound.png";
import AuthService from "../../../Services/auth.service"

const NotFound404 = () => {

    const redirectToUrl = () => {
        window.location.href = "/dashboard";
    }
  
  return (
        <>
            <div className="flex w-300 h-300">
                <img className="m-auto mt-5" src={NotFound} alt=""/>
            </div>
            <div className="row mt-2">
                <div className="d-flex col-md-12 items-center justify-content-center">
                <a href="#"> 
                    <span className="text-blue text-xl" onClick={()=>redirectToUrl()}>Retour à l'accueil</span>
                </a>
                </div>
            </div>
        </>
  );
};

export default NotFound404;
