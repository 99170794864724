import {useState} from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Pages/Header/Header';
import SideBar from '../Pages/SideBar/SideBar';

const DefaultLayout = () => {
 const [mobile,setMobile] = useState(false);

 const handleClickBurger = ()=>{
  setMobile(!mobile)
 }
  return (
    <>
      <div className="container-scroller bg-gray">
        <Header/>
        <div className='d-flex'>
          {/* <SideBar/> */}
          <div>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
